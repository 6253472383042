"use client";

import { FC, useState } from "react";
import { cn } from "@/utils/cn";
import { stringToHtmlId } from "@/utils/stringToHtmlId";
import { TbChevronDown } from "react-icons/tb";
import { AccordionItem } from "./types";

export type AccordionItemTitleElement = "h2" | "h3" | "h4" | "h5" | "h6";

type Props = {
	item: AccordionItem;
	TitleElement: AccordionItemTitleElement;
};

export const Item: FC<Props> = ({ item, TitleElement }) => {
	const [isExpanded, setIsExpanded] = useState(false);

	const normalizedTitle = stringToHtmlId(item.title);
	const titleId = `accordion-item-title-${normalizedTitle}`;
	const contentId = `accordion-item-content-${normalizedTitle}`;

	return (
		<div>
			<button
				id={titleId}
				onClick={() => setIsExpanded(!isExpanded)}
				className="flex w-full items-center justify-between gap-x-8 py-4 text-left"
				aria-controls={contentId}
				aria-expanded={isExpanded}
			>
				<TitleElement className="text-xl">{item.title}</TitleElement>

				<TbChevronDown
					className={cn(
						"transition-transform",
						isExpanded ? "rotate-0" : "rotate-90",
					)}
				/>
			</button>

			<section
				id={contentId}
				className={cn(
					"overflow-hidden text-lg transition-all",
					isExpanded ? "max-h-screen py-4 opacity-100" : "max-h-0 opacity-0",
				)}
				aria-labelledby={titleId}
			>
				{item.content}
			</section>
		</div>
	);
};
