"use client";

import { cn } from "@/utils/cn";
import MuxVideo from "@mux/mux-video-react";
import { FC, useState } from "react";
import { HERO_BACKGROUND_VIDEO_SRC } from "./constants";

type Props = {};

export const BackgroundVideo: FC<Props> = () => {
	const [canPlay, setCanPlay] = useState(false);

	return (
		<MuxVideo
			src={HERO_BACKGROUND_VIDEO_SRC}
			muted
			autoPlay
			loop
			controls={false}
			className={cn(
				"absolute left-0 top-0 -z-10 h-full w-full object-cover",
				canPlay ? "block" : "hidden",
			)}
			streamType="on-demand"
			onCanPlay={() => setCanPlay(true)}
			playsInline
		/>
	);
};
